import React from 'react';
import './SpaceDots.scss'

const SpaceDots = () => {
    let createDots = () => {
        let dots = [];
        for (let i = 0 ; i <= 550 ; i++){
            let startSize = Math.floor(Math.random() * 12) +1;
            let starColor =  Math.floor(Math.random() * 7) + 1 === 1 ? "#ffc78e"  : "white";
            let shouldShine = Math.floor(Math.random() * 3) + 1 === 1 ;
            dots.push(<div style={{
                left:Math.floor(Math.random() * 2500) +1,
                top:Math.floor(Math.random() * 1800) +1,
                width: startSize,
                height: startSize,
                backgroundColor: starColor,
                borderColor : starColor
            }}
            className={`spaceDot ${(starColor === "white" && shouldShine) ? 'shiningSpaceDot' : ''}`  }/>)
        }
        return dots
    }

    return (
        <div>
            {createDots().map((x) => x)}
        </div>
    );
};

export default SpaceDots;
