import React from 'react';
import './Planets.scss'
import mooncake from '../../assets/mooncake.png'
import tacoFelix from '../../assets/tacofelix.gif'

//Chookity sounds!
import audio from '../../assets/chookity/Chookity 1.wav';
import audio1 from '../../assets/chookity/Chookity 2.wav';
import audio2 from '../../assets/chookity/Chookity 3.wav';
import audio3 from '../../assets/chookity/Chookity 4.wav';
import audio4 from '../../assets/chookity/Chookity 5.wav';
import audio5 from '../../assets/chookity/Chookity 6.wav';
import audio6 from '../../assets/chookity/Chookity 7.wav';
import audio7 from '../../assets/chookity/Chookity 8.wav';
import audio8 from '../../assets/chookity/Chookity 9.wav';
import audio9 from '../../assets/chookity/Chookity 10.wav';

let audioArray = [audio, audio1, audio2, audio3, audio4, audio5, audio6, audio7,audio8,audio9];

function playAudio() {
    let selectedChookity = Math.floor(Math.random() * 10)
    console.log(`Selected chookity : ${selectedChookity}`)
    new Audio(audioArray[selectedChookity]).play();
}

const Planets = () => {
    return (
        <div className={'planets-container'}>
            <img src={mooncake} onClick={() => playAudio()} className={"planet-mooncake"}/>
            <img src={tacoFelix} className={"planet-felix"}/>
        </div>
    );
};

export default Planets;
