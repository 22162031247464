import './App.css';
import SpaceDots from "./components/SpaceDots/SpaceDots";
import Planets from "./components/Planets/Planets";

function App() {
  return (
    <div className="App">
      <SpaceDots/>
      <Planets/>
    </div>
  );
}

export default App;
